import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import MenuTemplate from '../../template/menu.template';
import { Col, Row, Table } from 'react-bootstrap';
import { getListInventoryAvailable } from '../../Redux/inventoryDuck'

const ExistanceProductsPage = ({ getListInventoryAvailable, fetching_list, data }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    useEffect(() => {
        getListInventoryAvailable()
    }, [])
    return (
        <MenuTemplate>
            <Row className='my-3'>
                <Col className='text-center'>
                    <h4>Existencias</h4>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={10}>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Producto</th>
                                <th>Inicial</th>
                                <th>Compras</th>
                                <th>Ventas</th>
                                <th>Bajas</th>
                                <th>Total Disponible</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.length > 0 ? list.map((d, k) => <tr key={k}>
                                <td>{k + 1}</td>
                                <td>{d.product}</td>
                                <td align='right'>{d.initial}</td>
                                <td align='right'>{d.purchases}</td>
                                <td align='right'>{d.sales}</td>
                                <td align='right'>{d.lowdown}</td>
                                <td align='right'>{parseFloat(d.total)}</td>
                            </tr>) : <tr>
                                <td colSpan={7} align='center'>No tienes Datos</td></tr>}

                        </tbody>
                    </Table>
                </Col>
            </Row>
        </MenuTemplate>
    )
}

const mapsState = (props) => {
    let { inventory } = props

    console.log('inventory', inventory);

    return {
        fetching_list: inventory.fetching_list,
        data: inventory.data,
    }
}


export default connect(mapsState, { getListInventoryAvailable })(ExistanceProductsPage);
import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Col, Form, Row, Table } from 'react-bootstrap';
import ItemTableBriefcase from './item.table.briefcase';
import { getListCredit, onCreateOrUpdateBriefcase } from '../../Redux/briefcaseDuck'
import { toogleAlert } from '../../Redux/alertDuck'

import { connect } from 'react-redux';
import { formatNumber } from '../../utils';
import ModalConfirmBriefcase from './modal.confirm.briefcase';
import { getParameterBySegment } from '../../Redux/parameterDuck'
import moment from 'moment';


const CreaateBriefcasePage = ({ getListCredit, fetching_credit, data_credit, onCreateOrUpdateBriefcase,
    fetching, row, data_client, toogleAlert }) => {

    const [list, setList] = useState([])
    const [listClient, setListClient] = useState([])
    const [listClientFiltered, setListClientFiltered] = useState([])
    const [client, setClient] = useState({})
    const [modal, setModal] = useState(false)
    const [form, setForm] = useState({
        date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD")
    })

    useEffect(() => {
        toogleAlert(true)
        getListCredit(form)
    }, [])

    useEffect(() => {
        if (fetching === 2) {
            window.location.href = `/cartera/${row.id}`
        }
    }, [fetching])

    useEffect(() => {
        if (fetching_credit === 2) {

            const uniqueClients = data_credit.reduce((acc, current) => {
                // Si el client_id no ha sido agregado antes, lo añadimos al acumulador
                if (!acc.some(item => item.client_id === current.client_id)) {
                    acc.push(current);
                }
                return acc;
            }, []);

            setListClient(uniqueClients)

            let list = data_credit.map(item => ({ ...item, checked: false }))
            setList(list)
            setListClientFiltered(list)
            toogleAlert(false)
        }
    }, [fetching_credit])


    const handleCheck = (id) => {
        setListClientFiltered(listClientFiltered.map(item =>
            item.id === id ? { ...item, checked: !item.checked } : item
        ));
    };

    const onConfirm = () => {
        let form = {}
        form.list_order = listClientFiltered.filter(d => d.checked === true);
        form.customer_id = client
        onCreateOrUpdateBriefcase(form)
    }

    const onFilter = (e) => {
        setClient(e.target.value)
        let _list = list.filter(d => parseInt(d.client_id) === parseInt(e.target.value))
        setListClientFiltered(_list)
    }

    const createDocument = () => {

        if (Object.keys(client).length === 0) {
            alert("Selecciona un Cliente")
            return
        }

        if (listClientFiltered.filter(d => d.checked === true).length === 0) {
            alert("Selecciona por lo Menos una Orden")
            return
        }
        setModal(true)
    }

    const onSelectAll = (e) => {
        if (e.target.checked) {

            const updatedItems = listClientFiltered.map((item) => ({
                ...item,
                checked: true,
            }));

            setListClientFiltered(updatedItems);
        } else {
            const updatedItems = listClientFiltered.map((item) => ({
                ...item,
                checked: false,
            }));

            setListClientFiltered(updatedItems);
        }
    }

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        })
    }

    const onFilterSearch = () => {
        toogleAlert(true)
        getListCredit(form)
    }

    return (
        <TemplateMenu>
            <Row>
                <Col>
                    <h4>Cartera Pendiente</h4>
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <div className="form-group">
                        <label>Escoge el CLIENTE</label>
                        <select className='form-control' onChange={onFilter}>
                            <option value={0}>Seleccione</option>
                            {listClient.map((d, k) => <option key={k} value={d.client_id}>{d.client}</option>)}
                        </select>
                    </div>
                </Col>
                <Col lg={2} className='d-flex align-items-center'>
                    <input type='checkbox' className='mx-2' onChange={onSelectAll} /> MARCAR TODOS
                </Col>
                <Col>
                    <div className="form-group">
                        <label>Fecha de Inicio</label>
                        <input
                            type="date"
                            className="form-control"
                            value={form.date_init}
                            name="date_init"
                            onChange={updateField}
                        />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label>Fecha Fin</label>
                        <input
                            type="date"
                            className="form-control"
                            value={form.date_end}
                            name="date_end"
                            onChange={updateField}
                        />
                    </div>
                </Col>
                <Col lg={2} className="d-flex align-items-center">
                    <button className='btn btn-sm btn-warning' onClick={onFilterSearch}>Filtrar</button>
                </Col>
                {listClientFiltered.filter(d => d.checked === true).length > 0 &&
                    <Col className='text-right'>
                        <button className='btn btn-sm btn-success' onClick={createDocument}>Crear Cuenta de Cobro {listClientFiltered.filter(d => d.checked === true).length}</button>
                    </Col>}

            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>FECHA</th>
                                <th>ORDEN</th>
                                <th>CLIENTE</th>
                                <th>TELEFONO</th>
                                <th>PLACA</th>
                                <th className='text-center'>VALOR</th>
                                <th>SELECCIONAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listClientFiltered.length > 0 ? listClientFiltered.map((d, k) => <ItemTableBriefcase key={k} item={d}
                                onSelectItem={handleCheck}
                            />) : <tr>
                                <td colSpan={4} align='center'>No tienes Documentos Pendientes</td></tr>}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={5}>TOTAL SELECCIONADO</th>
                                <th className='text-right'>{formatNumber(listClientFiltered.filter(d => d.checked === true).reduce(((a, b) => a + parseFloat(b.total)), 0))}</th>
                            </tr>
                            <tr>
                                <th colSpan={5}>TOTAL EN CARTERA</th>
                                <th className='text-right'>{formatNumber(listClientFiltered.reduce(((a, b) => a + parseFloat(b.total)), 0))}</th>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>

            <ModalConfirmBriefcase show={modal}
                onHide={() => setModal(false)}
                quantity={listClientFiltered.filter(d => d.checked === true).length}
                total={formatNumber(listClientFiltered.filter(d => d.checked === true).reduce(((a, b) => a + parseFloat(b.total)), 0))}
                onConfirm={onConfirm}
            />

        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { briefcase, client } = props

    return {
        fetching_credit: briefcase.fetching_credit,
        data_credit: briefcase.data_credit,
        fetching: briefcase.fetching,
        row: briefcase.row,
        fetching_client: client.fetching_client,
        data_client: client.clients,
    }
}

export default connect(mapsState, { getListCredit, onCreateOrUpdateBriefcase, getParameterBySegment, toogleAlert })(CreaateBriefcasePage);